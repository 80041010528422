//  -----{New Code} UI Changes Unleash NFTS ----- //

#main-menu {
  text-transform: uppercase;
}

.button-container .action-button span[role='img'],
.action-button span,
.ant-menu-submenu-vertical .ant-menu-submenu-title,
.nav-right .ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal {
  color: #2b3467 !important;
}

.ant-menu {
  background-color: #fae6e6 !important;
}

header#main-menu.top,
.main-nav,
header .ant-row .ant-menu,
.ant-menu-submenu .fa-plus,
.ant-menu-submenu .anticon,
a[href="/dashboard/list/"],
a[href="/chart/list/"],
a[href="/tablemodelview/list/"],
a[href="/login/"],
li.ant-menu-submenu-horizontal div.ant-menu-submenu-title,
.custom-sub-header {
  background-color: #010925 !important;
  // background-color: transparent !important;
  // background-image: linear-gradient(to right, rgba(255,0,0,0),rgb(125, 146, 167),rgb(55, 118, 182), rgba(3, 105, 206, 1));
  color: #ffffff !important;
  &:hover {
    color: #ffffff !important;
  }
}


.dropdown-menu-links {
  background-color: #171f4f;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  transition: all 0.3s;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: inline-flex !important;
  position: relative !important;
  box-shadow: none;
  border-radius: 4px;
}

li.ant-menu-item,
li.custom-sub-header-text,
li.ant-menu-submenu,
li.custom-sub-header {
  margin: 10px 0 !important;
  font-size: 16px !important;
  font-weight: 600;
  &:active {
    background-color: transparent !important;
  }
}

.button-background {
  background-color: #0054a6 !important;
}

.dashboard-component-chart-holder,
.dashboard-markdown,
.ant-tabs-nav-wrap {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10) !important;
  border-radius: 0 !important;
  margin: 10px !important;
  background-color: #ffffff5c !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.navbar-brand,
.navbar-brand img {
  width: 150px !important;
  height: 80px !important;
  font-weight: 500;
}
.navbar-inverse {
  border: none;
  background-color: #010925;
}

.loading {
  width: 40px !important;
}

.btn.btn-primary.btn-block {
  background-color: #171f4f !important;
}

.btn.btn-primary {
  background-color: #171f4f !important;
  border-width: 0;
  border-style: none;
  border-color: transparent;
  border-radius: 4px;
  color: #fae6e6 !important;
}

.grid-row.background--white {
  background: none !important;
}

.slice_container {
  background-image:  url('../images/bitscrunch-logo.svg');
  /*opacity:0.5;*/
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: center;
}

.panel.panel-default.chart-container {
  background-color: #ffffff5c !important;
}

.ant-tabs-content-holder {
  background-color: transparent !important;
}

body {
  background-color: #fae6e6;
  font-family: GTPressura;
  a {
    color: #2b3467 !important;
  }
}
body > text {
  display: none !important;
}

@font-face {
  font-family: 'GTPressura';
  src: url('../fonts/pressura/gt-pressura-beta-v1-black.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressura';
  src: url('../fonts/pressura/gt-pressura-beta-v1-bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressura';
  src: url('../fonts/pressura/gt-pressura-beta-v1-medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressura';
  src: url('../fonts/pressura/gt-pressura-beta-v1-regular.woff2')
    format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressura';
  src: url('../fonts/pressura/gt-pressura-beta-v1-light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
}

/* GT Pressura Extended for body copy? */
@font-face {
  font-family: 'GTPressuraExtended';
  src: url('../fonts/pressura/gt-pressura-beta-v1-extended-black.woff2')
    format('woff2');
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraExtended';
  src: url('../fonts/pressura/gt-pressura-beta-v1-extended-bold.woff2')
    format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraExtended';
  src: url('../fonts/pressura/gt-pressura-beta-v1-extended-medium.woff2')
    format('woff2');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraExtended';
  src: url('../fonts/pressura/gt-pressura-beta-v1-extended-regular.woff2')
    format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraExtended';
  src: url('../fonts/pressura/gt-pressura-beta-v1-extended-light.woff2')
    format('woff2');
  font-weight: 300;
  font-display: swap;
}

/* GT Pressura Mono for small text */

@font-face {
  font-family: 'GTPressuraMono';
  src: url('../fonts/pressura/gt-pressura-mono-beta-v1-black.woff2')
    format('woff2');
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraMono';
  src: url('../fonts/pressura/gt-pressura-mono-beta-v1-bold.woff2')
    format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraMono';
  src: url('../fonts/pressura/gt-pressura-mono-beta-v1-medium.woff2')
    format('woff2');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraMono';
  src: url('../fonts/pressura/gt-pressura-mono-beta-v1-regular.woff2')
    format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'GTPressuraMono';
  src: url('../fonts/pressura/gt-pressura-mono-beta-v1-light.woff2')
    format('woff2');
  font-weight: 300;
  font-display: swap;
}
@primary-color: #171f4f;
@indicator-color: @primary-color;
@brand-primary-dark1: #003f7c;
@brand-primary-dark2: #001f3e;
@brand-primary-light1: #1a78d4;
@brand-primary-light2: #3387d9;
@brand-primary-light3: #3387d9;
@brand-primary-light4: #66a5e2;
@brand-primary-light5: #b3d2f1;
//  -----{New Code} UI Changes Unleash NFTS End ----- //

@root-entry-name: default;